import {SgdBrandingMainApp} from "sgd-branding/src/_resources/ts/index";

class ScanguardMainApp extends SgdBrandingMainApp
{
    protected getHandlers(): Array<any>
    {
        return super.getHandlers().concat([]);
    }
}


let app = new ScanguardMainApp();
